import React from "react";
import Header from "../Components/Header";
import AppCards from "../Components/AppCards";
import Footer from "../Components/Footer";
import Particles from "react-tsparticles";

function Launcher() {
  return (
    <>
      <Header />
      <div className="container">
        <div className="col-12 col-md-8 mx-auto text-center mb-5 mt-2">
          <p>Welcome to 1000apps a centralised location for all 1000heads development applications</p>
        </div>
        <AppCards />
        <Footer />
      </div>
      <Particles
        id="tsparticles"
        options={{
          background: {
            color: {
              value: "#1a1a1a",
            },
          },
          fpsLimit: 30,
          particles: {
            color: {
              value: "#d02f85",
            },
            links: {
              color: "#964dbd",
              distance: 150,
              enable: true,
              opacity: 0.5,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
      />
    </>
  );
}

export default Launcher;
