import React from "react";
import appsJSON from "../Templates/apps.json";

function AppCards() {
  const apps = appsJSON.apps;

  return (
    <div className="col-12">
      <div className="row">
        {apps.map((apps, index) => (
          <div className="flip-card col-6 col-md-6 col-lg-3 p-0 animate__animated animate__zoomInUp">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <div className="cards">
                  <img alt={apps.title} src={apps.img} />
                </div>
              </div>
              <div className="flip-card-back">
                <div className="cards purp">
                  <div>
                    <div className="pills mx-auto">
                      <p>{apps.category}</p>
                    </div>
                    <h2>{apps.title}</h2>
                    <p>{apps.info}</p>
                    <a target="_blank" href={apps.link}>
                      <button>Launch App</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AppCards;
