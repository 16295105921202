import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";

function Header() {
  const fadeUp = useSpring({
    from: {
      opacity: 0,
      transform: "translateY(-250px)",
      transition: "0.3s ease-out",
      transitionDelay: "0.02s",
    },
    to: { opacity: 1, transform: "translateY(0)" },
  }); // animate text up

  const [offset, setOffset] = useState(0); // scroll Y axis number
  const [imgSize, setImgSize] = useState(); // image size class

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []); // trigger on scroll event

  useEffect(() => {
    if (offset >= 0) {
      setImgSize("sm");
    }
    if (offset === 0) {
      setImgSize("lg");
    }
  }); // scroll make logo responsive

  return (
    <>
      <div className="contain-head">
        <animated.div style={fadeUp} className="header">
          <img
            className={`${imgSize} logo  animate__animated animate__pulse animate__infinite`}
            src="assets/1000apps-logo.png"
          />
        </animated.div>
      </div>
    </>
  );
}

export default Header;
