import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import Launcher from "./Views/Launcher";
import ErrorPage from "./Views/Error";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Route path="/" component={Launcher} />
            <Route component={ErrorPage} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
